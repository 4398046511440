import {
    Commit,
    Dispatch,
    GetterTree,
    Module,
    MutationTree
} from "vuex";

import api from "@/api/axios";

export interface InterestResponse {
    data: string[];
}

const interestDefault = {
    data: [],
}

export interface State {
    data: InterestResponse;
}

const state: State = {
    data: interestDefault,
}

const getters: GetterTree<State, any> = {
    interests: (state: State) => state.data,
}

const mutations: MutationTree<State> = {
    async setInterests(state: State, payload: InterestResponse): Promise<void> {
        state.data = payload;
    },
}

export interface InterestRequest {
    interestId: string,
}

const actions = {
    async indexInterests({ commit }: {commit: Commit} )
    {
        try {
            const resp = await api.authed().get("/interest");
            if (!resp) throw new Error;
            await commit("setInterests", resp.data);
        } catch (error) {
            throw Error;
        }
    },
    async updateInterest({ dispatch, commit }: {dispatch: Dispatch, commit: Commit}, request: { interestId: string} )
    {
        try {
            const resp = await api.authed().patch(`/interest/${request.interestId}`, request);
            if (!resp) throw new Error;
        } catch (error) {
            throw Error;
        }
    }
}

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;