import {
    Commit,
    Dispatch,
    GetterTree,
    Module,
    MutationTree
} from "vuex";

import api from "@/api/axios";

export interface PaymentResponse {
    data: string[];
}

const paymentDefault = {
    data: [],
}

export interface State {
    data: PaymentResponse;
}

const state: State = {
    data: paymentDefault,
}

const getters: GetterTree<State, any> = {
    paymentMethods: (state: State) => state.data,
}

const mutations: MutationTree<State> = {
    async setPaymentMethods(state: State, payload: PaymentResponse): Promise<void> {
        state.data = payload;
    },
}

export interface AddressRequest {
    addressId: string,
}

export interface CardDetails {
    user: object,
    paymentMethod: string,
}

const actions = {
    async indexPaymentMethods({ commit }: {commit: Commit} )
    {
        try {
            const resp = await api.authed().get("/payment");
            if (!resp) throw new Error;
            await commit("setPaymentMethods", resp.data);
        } catch (error) {
            throw Error;
        }
    },
    async createCreditCard({commit}: {commit: Commit}, cardDetails: CardDetails) {
        try {
            const resp = await api.authed().post("/payment", cardDetails);
            if(!resp) throw new Error;
        } catch (error) {
            throw Error;
        }
    },
    async changeCreditCard({commit, dispatch}: {commit: Commit, dispatch: Dispatch}) 
    {
        try {
            const resp = await api.authed().patch("/payment");
            if (!resp) throw new Error;
            await dispatch("indexPaymentMethods");
        } catch (error) {
            throw Error;
        }
    },
    async updateDefaultPayment({dispatch}: {dispatch: Dispatch}, request: { paymentId: string })
    {
        try {
            const resp = await api.authed().patch(`/payment/default/${request.paymentId}`);
            if (!resp) throw new Error;
        } catch (error) {
            throw Error;
        }
    },
    async deletePayment({dispatch}: {dispatch: Dispatch}, request: { paymentId: string })
    {
        try {
            const resp = await api.authed().delete(`/payment/${request.paymentId}`);
            if (!resp) throw new Error;
        } catch (error) {
            throw Error;
        }
    }
}

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;