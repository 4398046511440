
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import PushNotifications from "@/helpers/PushNotifications";
import { SplashScreen } from '@capacitor/splash-screen';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  methods: {
    ...mapActions("user", ["getUserDetails"]),
  },
  mounted() {
    // SplashScreen.hide();
    PushNotifications.show();
    this.getUserDetails();
  },
});
