import {
    Commit,
    Dispatch,
    GetterTree,
    Module,
    MutationTree
} from "vuex";

import api from "@/api/axios";

export interface PerkResponse {
    data: string[];
}

const perkDefault = {
    data: [],
}

export interface State {
    data: PerkResponse;
    perk: object | null;
    default_payment: object | null,
}

const state: State = {
    data: perkDefault,
    perk: {},
    default_payment: {},
}

const getters: GetterTree<State, any> = {
    perks: (state: State) => state.data,
    perk: (state: State) => state.perk,
    defaultPayment: (state: State) => state.default_payment,
}

const mutations: MutationTree<State> = {
    async setPerks(state: State, payload: PerkResponse): Promise<void> {
        state.data = payload;
    },
    async setPerk(state: State, payload): Promise<void> {
        state.perk = payload.perk;
    },
    async setDefaultPayment(state: State, payload): Promise<void> {
        state.default_payment = payload.default_payment;
    },
}

export interface PerkOrderRequest {
    perkId: string,
}

const actions = {
    async indexPerks({ commit }: {commit: Commit} )
    {
        try {
            const resp = await api.authed().get("/perk");
            if (!resp) throw new Error;
            await commit("setPerks", resp.data);
        } catch (error) {
            throw Error;
        }
    },
    async showPerk({ commit }: {commit: Commit}, perkId: string )
    {
        try {
            const resp = await api.authed().get(`/perk/${perkId}`);
            if (!resp) throw new Error;
            await commit("setPerk", resp.data);
            await commit("setDefaultPayment", resp.data);
        } catch (error) {
            throw Error;
        }
    },
    async createPerkOrder({ commit, dispatch }: {commit: Commit, dispatch: Dispatch}, perkDetails: PerkOrderRequest )
    {
        try {
            const resp = await api.authed().post("/perk", perkDetails)
            if (!resp) throw new Error;
        } catch (error) {
            throw Error;
        }
    }
}

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;