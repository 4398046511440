import Vuex from "vuex";

import user from "./user/index";
import subscription from "./subscription/index";
import subscriptionDetails from "./subscription-details/index";
import packages from "./package/index";
import loyalty from "./loyalty/index";
import address from "./address/index";
import payment from "./payment/index";
import transaction from "./transaction/index";
import interest from "./interest/index";
import perk from "./perk/index";
import perkStock from "./perkStock/index";
import teepicker from "./teepicker/index";
import dashboard from "./dashboard/index";
import retention from "./retention/index";
import alert from "./alerts/index";

export default new Vuex.Store({
    modules: {
        user,
        subscription,
        subscriptionDetails,
        packages,
        loyalty,
        address,
        payment,
        transaction,
        interest,
        perk,
        perkStock,
        teepicker,
        dashboard,
        retention,
        alert
    }
});