import {
    Commit,
    GetterTree,
    Module,
    MutationTree
} from "vuex";

import api from "@/api/axios";

export interface State {
    data: [],
}

const state: State = {
    data: [],
}

const getters: GetterTree<State, any> = {
    // 
}

const mutations: MutationTree<State> = {
    // 
}

export interface LoyaltyRequest {
    data: [],
}

const actions = {
    async storeTeePicker({ commit }: {commit: Commit}, data: LoyaltyRequest)
    {
        try {
            const resp = await api.authed().post("/teepicker", data)
            if (!resp) throw new Error;
        } catch (error) {
            throw Error;
        }
    }
}

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;