import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { Storage } from "@capacitor/storage";
import store from "@/store/main";

import AppLayout from '../views/layout/AppLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: "/dashboard",
  },
  {
    path: '/login',
    component: () => import('@/views/login/Main.vue'),
    name: "login",
  },
  {
    path: "/forgot-password",
    component: () => import("@/views/forgot-password/Main.vue"),
    name: 'forgotPassword',
  },
  {
    path: "/reset-password",
    component: () => import("@/views/reset-password/Main.vue"),
    name: 'resetPassword',
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/members/dashboard/Main.vue'),
        name: 'dashboard',
      },
      {
        path: 'my-subscriptions',
        component: () => import('@/views/members/SubscriptionsTabLayout.vue'),
        name: 'mySubscriptions',
      },
      {
        path: 'loyalty',
        component: () => import('@/views/members/loyalty/Main.vue'),
        name: 'loyalty',
      },
      {
        path: 'account',
        component: () => import('@/views/members/more-options/Main.vue'),
        name: 'account',
      },
      {
        path: 'interests',
        component: () => import('@/views/members/interest/Main.vue'),
        name: 'interests',
      },
      {
        path: 'perks',
        component: () => import('@/views/members/perk/Main.vue'),
        name: 'perks',
      },
      {
        path: '/payment-methods',
        component: () => import('@/views/members/payment/Main.vue'),
        name: 'paymentMethods',
      },
    ],
  },
  {
    path: '/account-details',
    component: () => import('@/views/members/account/Main.vue'),
    name: 'accountDetails',
  },
  {
    path: '/address-details',
    component: () => import('@/views/members/address/Main.vue'),
    name: 'addressDetails',
  },
  {
    path: '/my-subscriptions/:subscription',
    component: () => import('@/views/members/subscription/Edit.vue'),
    name: 'editSubscription',
  },
  {
    path: '/my-subscriptions/new/:package',
    component: () => import('@/views/members/packages/checkout/Main.vue'),
    name: 'createSubscription',
  },
  {
    path: '/teepicker/:subscription',
    component: () => import('@/views/members/teepicker/Main.vue'),
    name: 'teepicker',
  },
  {
    path: '/perks/checkout/:perk',
    component: () => import('@/views/members/perk/checkout/Main.vue'),
    name: 'createPurchase',
  },
  {
    path: '/payment/new',
    component: () => import('@/views/members/payment/Update.vue'),
    name: 'createPayment',    
  },
  {
    path: '/payment/update/:payment',
    component: () => import('@/views/members/payment/Update.vue'),
    name: 'updatePayment',
  },
  {
    path: '/transactions',
    component: () => import('@/views/members/transactions/Main.vue'),
    name: 'transactions',
  },
  {
    path: '/transactions/:transaction',
    component: () => import('@/views/members/transactions/Details.vue'),
    name: 'editTransaction',
  },
  {
    path: '/interests',
    component: () => import('@/views/members/interest/Main.vue'),
    name: 'interests',
  },
  {
    path: '/perks',
    component: () => import('@/views/members/perk/Main.vue'),
    name: 'perks',
  },
  {
    path: '/payment-methods',
    component: () => import('@/views/members/payment/Main.vue'),
    name: 'paymentMethods',
  },
  {
    path: '/settings',
    component: () => import('@/views/members/settings/Main.vue'),
    name: 'settings',
  },
  {
    path: '/manage-account',
    component: () => import('@/views/members/settings/manage-account/Main.vue'),
    name: 'manageAccount',
  },
  {
    path: '/faqs',
    component: () => import('@/views/members/settings/faqs/Main.vue'),
    name: 'faqs',
  },
  {
    path: '/privacy-policy',
    component: () => import('@/views/members/settings/privacy-policy/Main.vue'),
    name: 'privacyPolicy',
  },
  {
    path: '/terms-and-conditions',
    component: () => import('@/views/members/settings/terms-and-conditions/Main.vue'),
    name: 'termsAndConditions',
  },
  {
    path: '/cancel-sub/:subscription',
    component: () => import('@/views/members/retention/Main.vue'),
    name: 'cancelSub',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// need to check if user is logged in.
router.beforeEach(async (to) => {
  const hasUser = await Storage.get({ key: "user" });
  if (!["/login", "/reset-password", "/forgot-password"].includes(to.path)) {
    if (hasUser.value === null) {
      return "/login";
    }
    await getUserFromStorage(hasUser.value);
  } else {
    if (hasUser.value !== null) {
      await getUserFromStorage(hasUser.value);
      return "/dashboard";
    }
  }
});

export async function getUserFromStorage(userString: string): Promise<void> {
  if (!store.getters["user/accessToken"]) {
    try {
      const user = JSON.parse(userString);
      store.commit("user/setUser", user);
    } catch (error) {
      store.dispatch("user/logout");
    }
  }
}

export default router
