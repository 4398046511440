import {
    Commit,
    Dispatch,
    GetterTree,
    Module,
    MutationTree
} from "vuex";

import api from "@/api/axios";

export interface TransactionResponse {
    data: string[];
}

const transactionDefault = {
    data: [],
}

export interface State {
    data: TransactionResponse;
    transactionDetails: object | null,
}

const state: State = {
    data: transactionDefault,
    transactionDetails: null,
}

const getters: GetterTree<State, any> = {
    transactions: (state: State) => state.data,
    transactionDetails: (state: State) => state.transactionDetails,
}

const mutations: MutationTree<State> = {
    async setTransactions(state: State, payload: TransactionResponse): Promise<void> {
        state.data = payload;
    },
    async setTransactionDetails(state: State, payload): Promise<void> {
        state.transactionDetails = payload;
    },
}

export interface TransactionRequest {
    page: number,
    perPage: number,
}

const actions = {
    async indexTransactions({ commit }: {commit: Commit}, request: TransactionRequest )
    {
        try {
            const resp = await api.authed().get(`/transaction?page=${request.page}&perPage=${request.perPage}`);
            if (!resp) throw new Error;
            await commit("setTransactions", resp.data);
        } catch (error) {
            throw Error;
        }
    },
    async showTransaction({ commit }: {commit: Commit }, transaction: string )
    { 
        try {
            const resp = await api.authed().get(`/transaction/${transaction}`);
            if (!resp) throw new Error;
            await commit("setTransactionDetails", resp.data[0])
        } catch (error) {
            throw Error;
        }
    },
}

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;