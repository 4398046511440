import {
    Commit,
    Dispatch,
    GetterTree,
    Module,
    MutationTree,
} from "vuex";

import api from "@/api/axios";

export interface SubscriptionsResponse {
    data: string[];
}

const subscriptionsDefault = {
    data: [],
}

export interface State {
    data: SubscriptionsResponse;
    error: string | null;
}

const state: State = {
    data: subscriptionsDefault,
    error: '',
};

const getters: GetterTree<State, any> = {
    subscriptions: (state: State) => state.data,
    error: (state: State) => state.error
};

const mutations: MutationTree<State> = {
    async setSubscriptions(state: State, payload: SubscriptionsResponse): Promise<void> {
        state.data = payload;
    },
    async setSubscription(state: State, payload: SubscriptionsResponse): Promise<void> {
        state.data = payload;
    }
}

export interface SubscriptionRequest {
    packageId: string,
}

export interface Subscription {
    subscriptionId: string,
}

const actions = {
    async indexSubscriptions({ commit }: {commit: Commit } )
    {
        try {
            const resp = await api.authed().get("/subscriptions");
            if (!resp) throw new Error;
            await commit("setSubscriptions", resp.data.subscriptions);
        } catch (error) {
            throw Error
        }
    },
    async createSubscription({ commit, dispatch }: {commit: Commit, dispatch: Dispatch}, subscriptionDetails: SubscriptionRequest )
    {
        try {
            const resp = await api.authed().post("/subscriptions", subscriptionDetails)
            if (!resp) throw new Error;
            await dispatch("indexSubscriptions");
        } catch (error) {
            throw Error;
        }
    },
    async restoreSubscription({ commit, dispatch }: {commit: Commit, dispatch: Dispatch}, subscription: Subscription)
    {
        try {
            const resp = await api.authed().post("/subscription/restore", subscription)
            if (!resp) throw new Error;
            await dispatch("indexSubscriptions");
        } catch (error) {
            throw Error;
        }
    }
};

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;