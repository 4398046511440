import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import api from "@/api/axios";
import toasts from "@/helpers/Toasts";
import router from "@/router";
import { isPlatform } from "@ionic/core";

export function register(): void {
  if (!isPlatform("mobileweb") && !isPlatform("desktop")) {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === "granted") {
        PushNotifications.register();
      }
    });

    PushNotifications.addListener("registration", (token: Token) => {
      api.authed().post("/device", {
        device_id: token.value,
      });
    });

    PushNotifications.addListener("registrationError", (error) => {
      console.log(`error on register ${JSON.stringify(error)}`);
    });
  }
}

export async function show(): Promise<void> {
  if (!isPlatform("mobileweb") && !isPlatform("desktop")) {
    PushNotifications.createChannel({
      id: "fcm_default_channel",
      name: "Notifications",
      description: "Notifications",
      importance: 5,
      visibility: 1,
      lights: true,
      vibration: true,
    });

    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        if (notification.body) {
          // toasts.pushNotification(notification);
        }
      }
    );

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (action: ActionPerformed) => {
        if (action.notification) {
          navigate(action.notification);
        }
      }
    );
  } else {
    console.log(`Push notifications: not available on web`);
  }
}

export function navigate(notification: PushNotificationSchema) {
  if (
    notification.data &&
    notification.data.type
  ) {
    switch (notification.data.type) {
      case "payment":
        router.push(`/payment/new`);
        break;
      case "perk":
        if (notification.data.perk) {
          router.push(`/perks/checkout/${notification.data.perk}`);
        }
        break;
      case "reward":
        router.push(`/loyalty`);
        break;
      case "teepicker":
        router.push('/my-subscriptions');
        break;
      }
  } else {
    router.push(`/`);
  }
}

export default {
  register,
  show,
  navigate,
};
