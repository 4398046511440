import {Commit, Dispatch, GetterTree, Module, MutationTree} from "vuex";
import api from "@/api/axios";
import { SubscriptionModel } from "@/models/subscription";

export interface State {
    subscription: SubscriptionModel | null;
    package: object | null;
    packageItems: Array<void> | null;
}

const state: State = {
    subscription: null,
    package: {},
    packageItems: [],
};

const getters: GetterTree<State, any> = {
    subscription: (state: State) => state.subscription,
    package: (state: State) => state.package,
    packageItems: (state: State) => state.packageItems,
};

const mutations: MutationTree<State> = {
    async setSubscription(state: State, payload): Promise<void> {
        state.subscription = payload.subscription;
        state.package = payload.package;
        state.packageItems = payload.subscription.package_items;
    }
}

const actions = {
    async showSubscription({ commit }: {commit: Commit }, subscription: string )
    { 
        try {
            const resp = await api.authed().get(`/subscriptions/${subscription}`);
            if (!resp) throw new Error;
            await commit("setSubscription", resp.data)
        } catch (error) {
            throw Error;
        }
    },
    async updateSubscriptionOptions({ dispatch }: {dispatch: Dispatch}, request: {subscriptionId: string} )
    {
        try {
            const resp = await api.authed().patch(`/subscriptions/${request.subscriptionId}`, request);
            if (!resp) throw new Error;
        } catch (error) {
            throw Error;
        } finally {
            dispatch('subscription/indexSubscriptions', null, {root: true})
        }
    }
};

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;