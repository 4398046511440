import {
    Commit,
    Dispatch,
    GetterTree,
    Module,
    MutationTree
} from "vuex";

import api from "@/api/axios";

export interface AddressResponse {
    data: string[];
}

const addressDefault = {
    data: [],
}

export interface State {
    data: AddressResponse;
}

const state: State = {
    data: addressDefault,
}

const getters: GetterTree<State, any> = {
    address: (state: State) => state.data,
}

const mutations: MutationTree<State> = {
    async setAddress(state: State, payload: AddressResponse): Promise<void> {
        state.data = payload;
    },
}

export interface AddressRequest {
    addressId: string,
}

const actions = {
    async showAddress({ commit }: {commit: Commit} ): Promise<void>
    {
        try {
            const resp = await api.authed().get("/address");
            if (!resp) throw new Error;
            await commit("setAddress", resp.data);
        } catch (error) {
            throw Error;
        }
    },
    async updateAddress({ commit, dispatch }: {commit: Commit, dispatch: Dispatch}, request: { addressId: string} )
    {
        try {
            const resp = await api.authed().patch(`/address/${request.addressId}`, request);
            if (!resp) throw new Error;
            await commit("setAddress", resp.data)
        } catch (error) {
            throw Error;
        }
    }
}

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;