import {
    ActionContext,
    Commit,
    Dispatch,
    GetterTree,
    Module,
    MutationTree,
} from "vuex";

import api from "@/api/axios";
import { Storage } from '@capacitor/storage';
import PushNotifications from "@/helpers/PushNotifications";

type Context = ActionContext<State, any>;

export interface State {
    accessToken: string | null,
    email: string | null,
    name: string | null,
    details: object | null,
    userIntent: object | null,
}

const state: State = {
    accessToken: null,
    email: null,
    name: null,
    details: null,
    userIntent: null,
};

const getters: GetterTree<State, any> = {
    accessToken: (state: State) => state.accessToken,
    email: (state: State) => state.email,
    name: (state: State) => state.name,
    userDetails: (state: State) => state.details,
    userIntent: (state: State) => state.userIntent,
};

export interface LoginPayload {
    email: string,
    name: string,
    accessToken: string
}

const mutations: MutationTree<State> = {
    async setUser(state: State, payload: LoginPayload): Promise<void> {
        state.accessToken = payload.accessToken;
        state.email = payload.email;
        state.name = payload.name;

        await Storage.set({
            key: "user",
            value: JSON.stringify(payload),
        });
    },
    async setUserDetails(state: State, payload): Promise<void> {
        state.details = payload;
    },
    async setUserIntent(state: State, payload): Promise<void> {
        state.userIntent = payload;
    },
    async setUserToStorage(state: State, payload: LoginPayload): Promise<void> {
        await Storage.set({
            key: "user",
            value: JSON.stringify(payload),
        });
    },
    async clearUser(state: State): Promise<void> {
        state.accessToken = null;
        state.email = null;
        state.name = null;
    },
    async clearUserFromStorage(): Promise<void> {
        await Storage.remove({ key: "user" });
    },
}

export interface LoginRequest {
    email: string,
    name: string,
}

export interface ResetRequest {
    email: string;
    password: string;
    password_confirmation: string;
    token: string;
}

const actions = {
    async login(
        { commit, dispatch }: { commit: Commit, dispatch: Dispatch }, login: LoginRequest) 
    {
        try {
            const resp = await api.unauthed().post("/auth/token", login);
            if (!resp) throw new Error;
            await commit("setUser", resp.data);
            await commit("setUserToStorage", resp.data);
            PushNotifications.register();
        } catch (error) {
            throw Error
        } finally {
            dispatch("getUserDetails");
        }
    },
    async getUserDetails(
        { commit, dispatch, state}: { commit: Commit; dispatch: Dispatch; state: State }, override: boolean ): Promise<void> {
        if (state.accessToken) {
            if (!state.details || override) {
                const resp = await api.authed().get("/details?email=" + state.email);
                if (!resp) {
                    dispatch("logout");
                }
                await commit("setUserDetails", resp.data.user);
                await commit("setUserIntent", resp.data.setupIntent);
            }
        }
    },
    async updateUser({ commit, dispatch }: {commit: Commit, dispatch: Dispatch}, request: { userId: string} )
    {
        try {
            const resp = await api.authed().patch(`/user/${request.userId}`, request);
            if (!resp) throw new Error;
            dispatch("getUserDetails");
        } catch (error) {
            throw Error;
        }
    },
    async forgotPassword(context: Context, reset: { email: string }): Promise<void> {
        try {
            const resp = await api.unauthed().post("/password/forgot", reset);
            if (!resp) throw Error;
        } catch (error) {
            throw Error;
        }
    },
    async resetPassword(context: Context, reset: ResetRequest): Promise<void> {
        try {
            const response = await api.unauthed().post("/password/reset", reset);
            if (!response) throw Error;
        } catch (error) {
            throw Error;
        }
    },
    async logout({state, commit}: {state: State, commit: Commit;}): Promise<void> {
        try {
            await api.authed().get("/logout", {
                headers: {
                    Authorization: `Bearer ${state.accessToken}`,
                },
            });
        } catch (error) {
            throw Error;
        } finally {
            commit("clearUser");
            commit("clearUserFromStorage");
        }
    },
    async deleteAccount({ commit, dispatch }: {commit: Commit, dispatch: Dispatch}, request: { userId: string}): Promise<void> {
        try {
            const resp = await api.authed().delete(`/user/${request.userId}`)
            if (!resp) throw new Error;
        } catch (error) {
            throw Error;
        }
    }
}

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;