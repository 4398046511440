
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';

import { ellipsisHorizontalOutline, ellipsisHorizontal, giftOutline, gift, shirtOutline, shirt, homeOutline, home } from 'ionicons/icons';

export default defineComponent({
  name: 'AppLayout',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      ellipsisHorizontalOutline,
      ellipsisHorizontal,
      giftOutline, 
      gift, 
      shirtOutline, 
      shirt, 
      homeOutline, 
      home
    }
  },
  computed: {
    route() {
      return this.$route.name;
    }
  }
});
