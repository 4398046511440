import {
    Commit,
    Dispatch,
    GetterTree,
    Module,
    MutationTree,
} from "vuex";

import api from "@/api/axios";

export interface State {
    teePickerCountdown: string | null;
    teePickerEndDate: string | null;
}

const state: State = {
    teePickerCountdown: '',
    teePickerEndDate: '',
};

const getters: GetterTree<State, any> = {
    countdownTime: (state: State) => state.teePickerCountdown,
    countdownTimeEnd: (state: State) => state.teePickerEndDate,
};

const mutations: MutationTree<State> = {
    async setTeePickerCountdown(state: State, payload): Promise<void> {
        state.teePickerCountdown = payload;
    },
    async setTeePickerEndDate(state: State, payload): Promise<void> {
        state.teePickerEndDate = payload;
    },
}

const actions = {
    async getTeePickerCountdown({ commit }: {commit: Commit } )
    {
        try {
            const resp = await api.authed().get("/dashboard");
            if (!resp) throw new Error;
            await commit("setTeePickerCountdown", resp.data.teePickerStartDate);
            await commit("setTeePickerEndDate", resp.data.teePickerEndDate);
        } catch (error) {
            throw Error
        }
    },
};

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;