import {
    Commit,
    GetterTree,
    Module,
    MutationTree
} from "vuex";

import api from "@/api/axios";

export interface LoyaltyResponse {
    counter: string;
    activeMonths: string;
    rewardDetails: object | null;
    reward: object;
    rewardMilestoneSix: object | null,
    rewardMilestoneTwelve: object | null,
}

const loyaltyDefault = {
    counter: null,
    activeMonths: null,
    rewardDetails: null,
    reward: null,
    rewardMilestoneSix: null,
    rewardMilestoneTwelve: null,
}

export interface State {
    counter: string | null,
    activeMonths: string | null,
    rewardDetails: object | null,
    reward: object | null,
    rewardMilestoneSix: object | null,
    rewardMilestoneTwelve: object | null,
}

const state: State = {
    counter: null,
    activeMonths: null,
    rewardDetails: null,
    reward: null,
    rewardMilestoneSix: null,
    rewardMilestoneTwelve: null,
}

const getters: GetterTree<State, any> = {
    counter: (state: State) => state.counter,
    activeMonths: (state: State) => state.activeMonths,
    rewardDetails: (state: State) => state.rewardDetails,
    reward: (state: State) => state.reward,
    rewardMilestoneSix: (state: State) => state.rewardMilestoneSix,
    rewardMilestoneTwelve: (state: State) => state.rewardMilestoneTwelve,
}

const mutations: MutationTree<State> = {
    async setCounter(state: State, payload: LoyaltyResponse): Promise<void> {
        state.counter = payload.counter;
    },
    async setActiveMonths(state: State, payload: LoyaltyResponse): Promise<void> {
        state.activeMonths = payload.activeMonths;
    },
    async setRewardDetails(state: State, payload): Promise<void> {
        state.rewardDetails = payload;
    },
    async setReward(state: State, payload): Promise<void> {
        state.reward = payload;
    },
    async setRewardMilestones(state: State, payload): Promise<void> {
        state.rewardMilestoneSix = payload.six;
        state.rewardMilestoneTwelve = payload.twelve;
    }
}

export interface LoyaltyRequest {
    rewardMonth: string,
}

const actions = {
    async indexLoyalty({ commit }: {commit: Commit} )
    {
        try {
            const resp = await api.authed().get("/loyalty");
            if (!resp) throw new Error;
            await commit("setCounter", resp.data);
            await commit("setActiveMonths", resp.data);
        } catch (error) {
            throw Error;
        }
    },
    async redeemLoyaltyReward({ commit }: {commit: Commit}, rewardMonth: LoyaltyRequest)
    {
        try {
            const resp = await api.authed().post("/loyalty", rewardMonth)
            if (!resp) throw new Error;
            await commit("setRewardDetails", resp.data);
        } catch (error) {
            throw Error;
        }
    },
    async getReward({ commit }: {commit: Commit}, rewardMonth: string)
    {
        try {
            const resp = await api.authed().get(`loyalty/${rewardMonth}`);
            if (!resp) throw new Error;
            await commit("setReward", resp.data);
        } catch (error) {
            throw Error;
        }
    },
    async getRewardMilestones({ commit }: {commit: Commit})
    {
        try {
            const resp = await api.authed().get('loyalty/reward/milestone');
            if (!resp) throw new Error;
            await commit("setRewardMilestones", resp.data);
        } catch (error) {
            throw Error;
        }
    }
}

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;