import {
    Commit,
    Dispatch,
    GetterTree,
    Module,
    MutationTree
} from "vuex";

import api from "@/api/axios";

export interface RetentionResponse {
    data: string[];
}

const retentionDataDefault = {
    data: [],
}

export interface State {
    data: RetentionResponse;
}

const state: State = {
    data: retentionDataDefault,
}

const getters: GetterTree<State, any> = {
    retentionData: (state: State) => state.data,
}

const mutations: MutationTree<State> = {
    async showRetentionData(state: State, payload: RetentionResponse): Promise<void> {
        state.data = payload;
    },
}

export interface RetentionRequest {
    type: string, // failed or successful
    solution: object | null, 
    reasons: object,
    subscription: object,
    postponement_length: object | null,
}

const actions = {
    async showRetentionData({ commit }: {commit: Commit}, subscription: string)
    {
        try {
            const resp = await api.authed().get(`/retention/${subscription}`);
            if (!resp) throw new Error;
            await commit("showRetentionData", resp.data);
        } catch (error) {
            throw Error;
        }
    },
    async storeRetention({ commit, dispatch }: {commit: Commit, dispatch: Dispatch}, retentionStoreRequest: RetentionRequest)
    {
        try {
            const resp = await api.authed().post('/retention', retentionStoreRequest);
            if (!resp) throw new Error;
        } catch (error) {
            throw Error;
        }
    }
}

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;