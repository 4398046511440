import { createApp } from 'vue'
import VueApp from './App.vue'
import router from './router';
import store from './store/main';
import { IonicVue } from '@ionic/vue';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import VueCountdown from '@chenfengyuan/vue-countdown';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';
import './theme/credit-card.css';

/* Tailwind css */
import './assets/styles/tailwind.css';

const app = createApp(VueApp)
  .use(IonicVue, { mode: "ios" })
  .use(router)
  .use(store)
  .use(VueCountdown);
  
router.isReady().then(() => {
  app.mount('#app');
});

App.addListener("appUrlOpen", function (event: URLOpenListenerEvent) {
  const urlArray: string[] = event.url.split("https://members.monthlyteeclub.com");
  if (urlArray && urlArray[1]) {
    router.push(urlArray[1]);
  }
});