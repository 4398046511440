import {
    Commit,
    GetterTree,
    Module, 
    MutationTree,
} from "vuex";

import api from "@/api/axios";

export interface PackageResponse {
    data: string[];
}

const packagesDefault = {
    data: [],
}

export interface State {
    data: PackageResponse;
    package: object | null;
    default_payment: object | null,
}

const state: State = {
    data: packagesDefault,
    package: {},
    default_payment: {},
}

const getters: GetterTree<State, any> = {
    packages: (state: State) => state.data,
    package: (state: State) => state.package,
    defaultPayment: (state: State) => state.default_payment,
}

const mutations: MutationTree<State> = {
    async setPackages(state: State, payload: PackageResponse): Promise<void> {
        state.data = payload;
    },
    async setPackage(state: State, payload): Promise<void> {
        state.package = payload.package;
    },
    async setDefaultPayment(state: State, payload): Promise<void> {
        state.default_payment = payload.default_payment;
    }
}

export interface PackageRequest {
    user_id: string,
}

const actions = {
    async indexPackages({ commit }: {commit: Commit} )
    {
        try {
            const resp = await api.authed().get("/packages");
            if (!resp) throw new Error;
            await commit("setPackages", resp.data.packages);
        } catch (error) {
            throw Error
        }
    },
    async showPackage({ commit }: {commit: Commit}, packageItem: string )
    {
        try {
            const resp = await api.authed().get(`/packages/${packageItem}`);
            if (!resp) throw new Error;
            await commit("setPackage", resp.data);
            await commit("setDefaultPayment", resp.data);
        } catch (error) {
            throw Error;
        }
    }
}

const module: Module<State, any> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;