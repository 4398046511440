import axios, { AxiosInstance, AxiosError, AxiosRequestHeaders } from "axios";
import store from '@/store/main';
import router from '@/router';
import { Storage } from "@capacitor/storage";

const test = false;
const prod = "https://api.monthlyteeclub.com/member";
const local = "http://localhost/member";

export interface StorageObject {
    value: string | null;
}
  
export async function hasUser(): Promise<StorageObject> {
    return await Storage.get({ key: "user" });
}
  
export async function bearerToken(): Promise<AxiosRequestHeaders> {
    const userJson = await hasUser();
    if (userJson.value !== null) {
        const user = JSON.parse(userJson.value);
        return {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
        };
    }
    return {};
}

export function authed(): AxiosInstance {
    const request = axios.create({
        baseURL: test ? local : prod,
        timeout: 60000,
    });
  
    // add headers to axios request
    request.interceptors.request.use(async (config) => {
        config.headers = await bearerToken();
        return config;
    });
  
    // requests a new token when the existing one expires
    request.interceptors.response.use(
        undefined,
        async function (error: AxiosError) {
            if (error.response && error.response.status === 401) {
                await store.dispatch("user/logout");
                router.replace("/login");
            }
        }
    );
  
    return request;
}

export function unauthed(): AxiosInstance {
    return axios.create({
        baseURL: test ? local : prod,
        timeout: 60000,
    });
}

export default {
    authed,
    unauthed,
}