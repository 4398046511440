import {
  Commit,
  Dispatch,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";

import api from "@/api/axios";

export interface availableFittingsResponse {
  data: string[];
}

export interface availableSizesResponse {
  data: string[];
}

const fittingsDefault = {
  data: [],
}

const sizesDefault = {
  data: [],
}

export interface State {
  fittings: availableFittingsResponse;
  sizes: availableSizesResponse;
  error: string | null;
}

const state: State = {
  fittings: fittingsDefault,
  sizes: sizesDefault,
  error: '',
};

const getters: GetterTree<State, any> = {
  fittings: (state: State) => state.fittings,
  sizes: (state: State) => state.sizes,
  error: (state: State) => state.error
};

const mutations: MutationTree<State> = {
  async availableFittings(state: State, payload: availableFittingsResponse): Promise<void> {
      state.fittings = payload;
  },
  async availableSizes(state: State, payload: availableSizesResponse): Promise<void> {
      state.sizes = payload;
  }
}

export interface SizingKidsRequest {
  package_id: string,
  options: Array<any>,
  type: string,
}

export interface SizingRequest {
  package_id: string,
  options: Array<any>,
  type: string,
}

export interface FittingRequest {
  package_id: string,
  options: Array<any>,
}

const actions = {
  async stockKid({ commit, dispatch }: {commit: Commit, dispatch: Dispatch}, sizing: SizingKidsRequest )
  {
      try {
          const resp = await api.authed().post("/perk/stock", sizing)
          if (!resp) throw new Error;
      } catch (error) {
          throw Error;
      }
  },
  async stockAdult({ commit, dispatch }: {commit: Commit, dispatch: Dispatch}, sizing: SizingRequest )
  {
      try {
          const resp = await api.authed().post("/perk/stock", sizing)
          if (!resp) throw new Error;
          return resp;
      } catch (error) {
          throw Error;
      }
  },
  async fittingAdult({ commit, dispatch }: {commit: Commit, dispatch: Dispatch}, fitting: FittingRequest )
  {
      try {
          const resp = await api.authed().post("/perk/fitting", fitting)
          if (!resp) throw new Error;
          return resp;
      } catch (error) {
          throw Error;
      }
  },
};

const module: Module<State, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default module;